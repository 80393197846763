<template>
  <div class="donate-home">
    <!--  Bannar  -->
    <Loader :isLoading="isLoading"></Loader>
    <div id="theme-banner-one">

      <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
        <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/' + 'Group-1565.svg'"
             style="margin-top: 100px; width: 1000px"
             alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
      </div>

      <!-- <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg'" alt="" class="shape-one"> -->
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg'" alt="" class="shape-two">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-10.svg'" alt="" class="shape-three">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg'" alt="" class="shape-four">
      <div class="round-shape-one"></div>
      <div class="round-shape-two"></div>
      <div class="round-shape-three"></div>
      <div class="round-shape-four"></div>
      <div class="container">
        <div class="main-wrapper">
          <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
            ساهم في برامج <b> التميز</b>
            <br>
            وكن عوناً لمن هم
            <br>
            أهلٌ للعون
          </h1>
          <p class="sub-title wow fadeInUp animated mt-5" data-wow-delay="0.9s">"والله في عون العبد ما كان
            العبد في عون أخيه"</p>
        </div> <!-- /.main-wrapper -->
      </div> <!-- /.container -->
    </div>

    <!--  Programs  -->
    <div class="our-service service-standard">
      <div class="container">
        <div class="theme-title-three text-center mb-80">
          <h2 class="title">برامج العطاء</h2>
          <p>يفتحُ بوابَةَ العطاءِ أيادٍ معطاء .. تسعى لتحققَّ أحلاماً صغيرة .. لكنها في عيونهم كبيرة ..</p>
        </div> <!-- /.theme-title-three -->

        <div class="clearfix">
          <div class="row">
            <div class="col-lg-6 col-sm-12 p-4 wow fadeInUp animated" v-for="(item, key) in programsList">
              <div class="single-counter-box" @click="NavigateToDetailsPage(item.id)">
                <img class="m-auto" :src="item.image_preview_url">
                <span class="centered">
                                        <h4 class="text-white text-nowrap">
                                          {{ item.title }}
                                        </h4>
                                        <p class="mb-0 mt-5 text-white" v-html="item.short_desc"/>
                                    </span>
              </div>
            </div>
          </div> <!-- /.row -->
        </div> <!-- /.clearfix -->
      </div> <!-- /.container -->
    </div>
    <!--  Donate Ways  -->
    <div>
      <div class="theme-title-one text-center hide-pr show-pr mt-5">
        <h2 class="main-title mb-3">طرق التبرع </h2>
        <span class="h4"> اختر الطريقة الأنسب إليك </span>
      </div>
      <div class="seo-work-progress p-0">
        <div class="container">

          <div class="row single-work-list mt-5 pb-5 wow fadeInRight animated">
            <div class="col-lg-6 py- second">
              <div class="text-wrapper">
                <div class="icon-box">
                  <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1242.svg'"
                       alt="">
                </div>
                <h2 class="title mb-5">التبرع الالكتروني المباشر</h2>
                <p>يتم استلام التبرع من قبل الموظف المسؤول بعد تواصلكم مع الجمعية والاتفاق على مكان الاستلام</p>
                <router-link :to="{name:'DonationForm'}" class="contact-button line-button-one mt-5">
                  المزيد
                </router-link>
              </div> <!-- /.text-wrapper -->
            </div>
            <div class="col-lg-6 py- first">
              <div class="img-box clearfix">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/'+ 'Group-786.svg'"
                     alt="">
              </div> <!-- /.img-box -->
            </div>
          </div>

          <div class="row single-work-list py-5 wow fadeInLeft animated">
            <div class="col-lg-6 py-">
              <div class="img-box clearfix">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/'+ 'Group-785.svg'"
                     alt="">
              </div> <!-- /.img-box -->
            </div>
            <div class="col-lg-6 py-">
              <div class="text-wrapper">
                <div class="icon-box">
                  <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1243.svg'"
                       alt="">
                </div>
                <h2 class="title mb-5">البنك</h2>
                <p>تبرع على حساباتنا البنكية</p>
                <ul>
                  <li>بنك الشام الإسلامي - رقم الحساب : 7000136</li>
                  <li>بنك بركة الإسلامي - رقم الحساب : 7000838</li>
                </ul>
                <!--              <a href="/contact-us" class="contact-button line-button-one mt-5">المزيد</a>-->
              </div> <!-- /.text-wrapper -->
            </div>
          </div>

          <div class="row single-work-list pt-5 wow fadeInUp animated">
            <div class="col-lg-6 py-">
              <div class="text-wrapper">
                <div class="icon-box">
                  <img :src="$websiteEnvironment.assetsDir + '/website-svgs/home/'+ 'Group-1244.svg'"
                       alt="">
                </div>
                <h2 class="title mb-5"> رسائل الـ SMS</h2>

                <p> قم بإرسال رسالة إلى الرقم الرباعي <b>1059</b> واختر واحدة من الباقات التالية كمحتوى للرسالة : </p>
                <ul>
                  <li><b>باقات MTN</b>
                    <ol> باقة 200 ليرة - باقة 300 ليرة - باقة 400 ليرة - باقة 500 ليرة - باقة 1000 ليرة - باقة 5000 ليرة
                      - باقة 10000 ليرة
                    </ol>
                  </li>
                  <li><b>باقات SYRIATEL</b>
                    <ol>باقة 200 ليرة - باقة 500 ليرة - باقة 1000 ليرة</ol>
                  </li>
                  <br>
                </ul>
              </div> <!-- /.text-wrapper -->
            </div>
            <div class="col-lg-6 py-">
              <div class="img-box clearfix">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donation-programs/'+ 'Group 2003.svg'"
                     alt="">
              </div> <!-- /.img-box -->
            </div>
          </div>


        </div> <!-- /.container -->
      </div> <!-- /.seo-work-progress -->
    </div>
  </div>
</template>

<script>
import DynamicMasterLayout from '@/layouts/DynamicMasterLayout'
import Loader from '../../shared/components/Loader'
import {RepositoryFactory} from "../../website/repositories/RepositoryFactory";

const blogRepository = RepositoryFactory.get('blog');

export default {
  name: 'DonateHome',
  metaInfo: {
    title: 'برامج التبرع',
  },
  components: {
    DynamicMasterLayout,
    Loader
  },
  data() {
    return {
      programsList: [],
      isLoading: true,
    }
  },
  created() {
    blogRepository.fetchAllBlogsByType('DonateProgram').then((data) => {
      this.programsList = data.blogs;
      this.isLoading = false;
    })
  },

  methods: {
    NavigateToDetailsPage(blogId) {
      this.$router.push({name: 'Full-Details', query: { id: blogId } });
    },
  }
}
</script>


<style scoped>
#theme-banner-one .illustration {
  position: absolute;
  left: -10%;
  top: 0%;
  width: 55%;
}

/* should be optimized for small and very small screeens */
.centered {
  position: absolute;
  top: 50%;
  left: 67%;
  transform: translate(-50%, -50%);
}

p {
  font-size: 20px;
}

@media (max-width: 576px) {
  h4 {
    font-size: 20px !important;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 65%;
    transform: translate(-50%, -50%);
  }

  .mt-5, .my-5 {
    margin-top: 1rem !important;
  }

  p {
    font-size: 13px;
  }
}

.seo-work-progress .single-work-list .text-wrapper .title {
  font-size: 48px;
  line-height: 60px;
  padding: 0px !important;
  text-decoration: none;
}

/* contrlling the order of the grid system manually :P */
@media only screen and (max-width: 768px) {
  .first {
    order: 1;
  }

  .second {
    order: 2;
  }

}

.single-counter-box:hover {
  cursor: pointer;
}

</style>
