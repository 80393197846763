<template allow="geolocation*">
  <div class="training-form">
    <div id="theme-banner-four">
      <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donate-now/Group-1136.svg'"
           alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-8.svg'" alt="" class="shape-one">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-9.svg'" alt="" class="shape-two">
      <img :src="$donateEnvironment.assetsDir + '/donate-banner/shape/shape-11.svg'" alt="" class="shape-four">
      <div class="round-shape-one"></div>
      <div class="round-shape-two"></div>
      <div class="round-shape-three"></div>
      <div class="round-shape-four"></div>
      <div class="container">
        <div class="main-wrapper">
          <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s"> التبرّع </h1>
          <h4 class="wow fadeInUp animated mt-5" data-wow-delay="0.9s">
            تبرّع وكن عوناً لمن هم أهلٌ للعون
          </h4>
        </div> <!-- /.main-wrapper -->
      </div> <!-- /.container -->
    </div>
    <div class="wrapper">
      <div class="row">
        <div class="col-lg-7 p-5 mobilePadding last">
          <div class="container">
            <div class="text">
              <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
                <b>التبرّع</b>
              </h1>
              <h4 class=" wow fadeInUp animated mt-5 subTitle" data-wow-delay="0.9s" style="font-size: 36px">
                تبرّع وكن عوناً لمن هم أهلٌ للعون
              </h4>
            </div>
          </div>
        </div>
        <div class="col-lg-5 first p-5">
          <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
            <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donate-now/' + 'Group-1136.svg'"
                 alt=""
                 class="screen-one wow fadeInLeft animated" data-wow-duration="2s"
                 style=" width: 80%; margin-right: 10%">
          </div>
        </div>
      </div>
    </div>
    <div class="pt-5">
      <DonateFormComponent></DonateFormComponent>
    </div>
  </div>

</template>

<script>

  import Loader from '../../shared/components/Loader'
  import DonateFormComponent from "../donate-components/DonateFormComponent";

  export default {
    name: 'websiteDonationForm',
    metaInfo: {
      title: 'تبرع لِفعل الخير'
    },
    components: {
      Loader,
      DonateFormComponent
    }
  }
</script>

<style scoped>
  #theme-banner-one .illustration {
    position: absolute;
    left: 3%;
    top: 10%;
    width: 45%;
  }
  #theme-banner-four .screen-one {
    left: 5%;
    z-index: 1;
    padding-top: 150px
  }
  #theme-banner-four .main-wrapper {
    padding-bottom: 100px;
  }
  .wrapper {
    padding: 200px 0 50px;
    display: none;
  }
  .text {
    padding: 48px 80px 48px 48px;
  }
  @media (width: 2560px) {
    #theme-banner-four .screen-one{
      padding-top: 0px !important;
    }
  }
  @media (max-width: 575px) {
    .wrapper {
      padding: 120px 0 0px;
      display: block;
    }
    .first {
      order: 1 !important;
    }

    .mobilePadding {
      padding: 0px !important;
    }

    .last {
      order: 2 !important;
    }

    .text {
      padding: 25px !important;
    }

    .main-title {
      font-size: 37px;
    }
    .subTitle {
      font-size: 18px !important;
    }
    #theme-banner-four{
      display: none;
    }
  }
</style>
