<template>
    <div class="jumbotron-bg custom-padding-bottom">
        <footer class="theme-footer-one">
            <div class="top-footer ">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-sm-6 col-12 about-widget">
                            <a href="/" class="logo" style="width: 80%;">
                                <img src="assets/images/logo/large-tamayoz-logo-new.svg" alt="">
                            </a>
                            <a href="javascript:void(0)" class="email">info@t-tamayoz.com</a>
                            <a href="javascript:void(0)" class=email>it_huda@t-tamayoz.com</a>
                        </div> <!-- /.about-widget -->
                        <div class="col-lg-3 col-lg-3 col-sm-6 col-12 footer-list">
                            <h5 class="title">للتواصل</h5>
                            <ul>
                                <li><a href="javascript:void(0)" class="phone">رباعي: 9904</a></li>
                                <li><a href="javascript:void(0)" class="phone">الهاتف: 61 - 963118833360+</a></li>
                                <li><a href="javascript:void(0)" class="phone">الجوال: 61 - 963948833360+</a></li>
                            </ul>
                        </div> <!-- /.footer-recent-post -->
                        <div class="col-lg-3 col-sm-6 col-12 footer-list">
                            <h5 class="title">حساباتنا البنكية</h5>
                            <ul>
                                <li><a href="javascript:void(0)">بنك البركة : 7000838</a></li>
                                <li><a href="javascript:void(0)">بنك الشام الإسلامي : 7000136</a></li>
                                <li><a href="javascript:void(0)">بنك سورية الدولي الإسلامي : 202222</a></li>
                            </ul>
                        </div> <!-- /.footer-list -->
                        <div class="col-lg-3 col-lg-2 col-sm-6 col-12 footer-information">
                            <h5 class="title">العنوان</h5>
                            <p>دمشق - ميدان كورنيش <br>مقابل صيدلية الكواكبي</p>
                            <ul style="margin-right: -15px;">
                                <li><a href="https://www.facebook.com/Tyateem/"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="https://twitter.com/t_tamayoz"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                                <li><a href="https://www.youtube.com/user/tyateem"><i class="fa fa-youtube" aria-hidden="true"></i></a>
                                </li>
                                <li><a href="https://www.instagram.com/t_tamayoz/"><i class="fa fa-instagram"
                                                                                      aria-hidden="true"></i></a></li>
                                <li><a href="https://t.me/t_tamayoz"><i class="fa fa-telegram" aria-hidden="true"></i></a></li>
                            </ul>
                        </div>
                    </div> <!-- /.row -->
                </div> <!-- /.container -->
            </div> <!-- /.top-footer -->
            <div class="container">
                <div class="bottom-footer">
                    <div class="clearfix">
                        <p>AL Tamayoz - {{currentYear}}</p>
                        <ul>
                            <li>
                              <router-link :to="{ name: 'ContactUs' }" >تواصل معنا</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer> <!-- /.theme-footer-one -->
        <!-- Scroll Top Button -->
        <button class="scroll-top tran3s">
            <i class="fa fa-angle-up" aria-hidden="true"></i>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'the-footer',
      computed:{
          currentYear(){
            return  new Date().getFullYear();
          }

      }
    }
</script>

<style scoped>
    .jumbotron-bg {
        background: #483c51;
    }
    .custom-padding-bottom {
        padding-bottom: 0rem !important;
    }

    .theme-footer-one, * {
        color: white !important;
    }

    .theme-footer-one .bottom-footer {
        border-top: none;
        margin-top: 90px;
        padding: 35px 0;
    }
    @media (max-width: 575px) {
      .jumbotron-bg {
        z-index: 3;
        position: absolute;
      }
    }
</style>

