import DonateHome from './donate-views/DonateHome.vue'
import MasterLayout from '../../layouts/MasterLayout'
import DonationInviteForm from './donate-views/DonationInviteForm.vue'
import DonationInviteFormComingSoon from './donate-views/DonationInviteFormComingSoon.vue'
import DonationForm from './donate-views/DonationForm.vue'
import DonationSuggestionForm from './donate-views/DonationSuggestionForm.vue'

const donateRoutes = [
    {
        path: '/donate',
        name: 'DonateHome',
        component: DonateHome,
        meta: {layout: MasterLayout}
    },
    {
        path: '/donation-form',
        name: 'DonationForm',
        component: DonationForm,
        meta: {layout: MasterLayout}
    },
    // {
    //     path: '/donation-suggestion-form',
    //     name: 'DonationSuggestionForm',
    //     component: DonationSuggestionForm,
    //     meta: {layout: MasterLayout}
    // },
    {
        path: '/donation-invite-form',
        name: 'DonationInviteForm',
        component: DonationInviteForm,
        meta: {layout: MasterLayout}
    },
    {
        path: '/donation-invite-comingSoon',
        name: 'DonationInviteFormComingSoon',
        component: DonationInviteFormComingSoon,
        meta: {layout: MasterLayout}
    }

]

export default donateRoutes
