<template>
    <div>
        <Loader :isLoading="isLoading"></Loader>
        <div id="theme-banner-one">
            <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donate-now/' + 'Group-1136.svg'"
                     style="margin-top: 100px; width: 1000px"
                     alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
            </div>

            <div class="container">
                <div class="main-wrapper">
                    <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
                        دعوة تبرّع
                    </h1>
                    <h4 class=" wow fadeInUp animated mt-5" data-wow-delay="0.9s">
                        قم بدعوة المعارف والأصدقاء للتبرع لجمعية التميز
                        <br> وكن شريكاً في الأجر والثواب
                    </h4>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="row mt-5">
                <div class="col">
                    <div class="contact-form">
                        <ValidationObserver v-slot="{handleSubmit}">
                            <form class="form form-style-two" id="contact-form"
                                  @submit.prevent="handleSubmit(onSubmit)">
                                <div class="controls row">
                                    <div class="form-group col-lg-6 mb-5 px-5">
                                        <label for="">اسمك الثلاثي*</label>
                                        <ValidationProvider name="الاسم الثلاثي" rules=""
                                                            v-slot="{ errors }">
                                            <input type="text"
                                                   name="sender_name"
                                                   placeholder="الاسم الثلاثي"
                                                   v-model="invitation.sender_name">
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="controls row">
                                    <div class="form-group col-lg-12 px-5">
                                        <label for="">رسالتي: <br> <br>
                                          <strong>مثال على الرسالة</strong> :
                                            {{invitation.messageDraft}} </label>
                                        <br>
                                        <ValidationProvider name="الرسالة" rules="required"
                                                            v-slot="{ errors }">
                                            <input type="text"
                                                   name="message"
                                                   placeholder="أضف رسالتك"
                                                   v-model="invitation.sender_message">
                                            <span class="text-danger">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>

                                <div class="controls row">
                                    <div class="form-group col-lg-12 px-5">
                                        <div class="row">
                                            <div class="col-12 mb-5 d-inline-flex">
                                                <span class="fa fa-envelope text-success mt-1 mr-2"></span>
                                                <h5>دعوة عبر رسالة نصية</h5>
                                            </div>

                                            <div class="form-group col-lg-6 mb-5 px-5"
                                                 v-for="(item, index) in invitation.receiver_mobiles"
                                                 :key="index">
                                                <label>رقم الهاتف المحمول*</label>
                                                <div class="d-flex">
                                                    <ValidationProvider name="رقم الهاتف المحمول"
                                                                        rules="required|numeric" v-slot="{ errors }">
                                                        <input type="number" name="number"
                                                               placeholder="رقم الهاتف المحمول"
                                                               v-model="invitation.receiver_mobiles[index]">
                                                        <span class="text-danger">{{ errors[0] }}</span>
                                                    </ValidationProvider>

                                                    <button type="button" v-if="index == 0 ? false : true"
                                                            title="حذف الرقم"
                                                            v-on:click="removeNumberInput()">
                                                        <i class="fa fa-trash fa-1x text-danger"></i>
                                                    </button>
                                                </div>
                                            </div>

                                            <div class="col-12">
                                                <div class="d-flex">
                                                    <button type="button" class="white-shdw-button m-0 mr-2"
                                                            v-on:click="addNewNumberInput()">
                                                        <span class="fa fa-plus mr-3"></span>
                                                        إضافة رقم آخر
                                                    </button>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </form>
                        </ValidationObserver>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mb-150">
            <div class="main-wrapper float-right">
                <button type="submit" form="contact-form" class="more-button solid-button-one wow fadeInLeft animated"
                        data-wow-delay="1.5s"
                        style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;">
                    إرسال
                </button>
            </div>
        </div>

    </div>
</template>

<script>
    import {RepositoryFactory} from "../repositories/RepositoryFactory";
    import Loader from '../../shared/components/Loader'

    const invitationRepo = RepositoryFactory.get('invitation');

    export default {
        name: 'websiteDonationInviteForm',
        metaInfo: {
            title: 'دعوة للتبرع'
        },
        data() {
            return {
                invitation: {
                    receiver_mobiles: [null],
                    messageDraft:
                        'أدعوك للتبرع لجميعة التميز في كفالة اليتيم لباب التجهيزات الطبية سائلاً المولى أن يجزيكم الخير ويجعلنا في الثواب مشتركين',
                    sender_message: '',
                    sender_name: '',
                },
                isLoading: true,
            }
        },
        created() {
            this.isLoading = false;
        },
        components: {
            Loader
        },
        methods: {
            addNewNumberInput() {
                this.invitation.receiver_mobiles.push('');
                this.$toasted.show('تمت إضافة خانة جديدة')
            },
            removeNumberInput(index) {
                this.invitation.receiver_mobiles.splice(index, 1);
                this.$toasted.show('تم حذف الخانة')
            },
            resetInput() {
                this.invitation.receiver_mobiles = [null];
                this.invitation.sender_message = '';
                this.invitation.sender_name = '';
            },
            onSubmit() {
                this.isLoading = true;
                invitationRepo.save({
                    'sender_name': this.invitation.sender_name,
                    'receiver_mobiles': this.invitation.receiver_mobiles,
                    'message': this.invitation.messageDraft + ' ' + this.invitation.sender_message
                }).then(response => {
                        this.isLoading = false;
                        this.resetInput();
                        this.$swal({
                            icon: 'success',
                            title: 'تم الإرسال بنجاح',
                            text: response.message
                        })
                    }
                );
            }
        }
    }
</script>

<style scoped>

    .solid-inner-banner {
        background: #fafcff;
        text-align: right;
        padding: 265px 0 0;
    }

    #theme-banner-one {
        background: none;
        position: relative;
    }

    #theme-banner-one .illustration {
        position: absolute;
        left: 3%;
        top: 10%;
        width: 45%;
    }

</style>
