import {environment} from "../donate-environment";
import {httpClient} from "../../shared/services";

export default {
    save(donation) {
        const url = environment.donateApiBaseUrl + 'donation';
        return httpClient.post(url, donation)
            .then(res => res.data)
            .catch(error => console.log(error))
    },
}
