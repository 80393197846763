<template>
    <div class="training-form">
        <!-- <div class="solid-inner-banner">
            <div class="container text-center">
                <h1 class="pb-5 font-wight-bold">إقتراح تبرّع</h1>
                <h5 class="pb-5">قدّم لنا إقتراحات عن أبواب خير جديدة</h5>
            </div>
         </div>   -->

        <div id="theme-banner-one">

            <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/donate-now/' + 'Group-1136.svg'"
                     style="margin-top: 100px; width: 600px"
                     alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
            </div>

            <div class="container">
                <div class="main-wrapper">
                    <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
                        إقتراح تبرّع
                    </h1>
                    <h4 class=" wow fadeInUp animated mt-5" data-wow-delay="0.9s">
                        قدّم لنا إقتراحات عن أبواب خير جديدة
                    </h4>
                </div>
            </div>

        </div>


        <div class="container">
            <div class="row mt-5">
                <div class="col">
                    <div class="contact-form">
                        <form class="form form-style-two" id="contact-form" data-toggle="validator" novalidate="true">
                            <div class="messages"></div>
                            <div class="controls row">

                                <div class="form-group has-error has-danger col-lg-6 mb-5 px-5">
                                    <label for="">الاسم الثلاثي*</label>
                                    <input type="text" name="fullName" placeholder="الاسم الثلاثي" required="required"
                                           data-error="رجاءّ قم بادخال اسمك">
                                </div>

                                <div class="form-group has-error has-danger col-lg-6 px-5">
                                    <label for="">رقم الهاتف*</label>
                                    <input type="number" name="phoneNumber" placeholder="رقم الهاتف" required="required"
                                           data-error="رجاءّ قم بادخال مكان سكنك">
                                </div>

                                <div class="form-group has-error has-danger col-12 px-5">
                                    <label for="">المقترح*</label>
                                    <textarea type="text" name="phoneNumber" placeholder="المقترح" required="required"
                                              data-error="رجاءّ قم بادخال مكان سكنك">
                                        </textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <div class="container mb-150">
            <div class="main-wrapper float-right">
                <a href="about-us-standard.html" class="more-button solid-button-one wow fadeInLeft  animated"
                   data-wow-delay="1.5s"
                   style="visibility: visible; animation-delay: 1.5s; animation-name: fadeInLeft;">
                    إرسال
                </a>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'websiteDonationSuggestionForm',
    metaInfo: {
      title: 'إقتراح تبرع',
    },
    }
</script>

<style scoped>

    .solid-inner-banner {
        background: #fafcff;
        text-align: right;
        padding: 265px 0 0;
    }

    .custom-map-height {
        height: 50vh;
    }

    #theme-banner-one {
        background: none;
        position: relative;
    }

    #theme-banner-one .illustration {
        position: absolute;
        left: 3%;
        top: 10%;
        width: 45%;
    }


</style>
