import donationRepository from './donationRepository'
import invitationRepository from './invitationRepository'


const repositories = {
    donation: donationRepository,
    invitation: invitationRepository
};

export const RepositoryFactory = {
    get: name => repositories[name]
};
