<template>
    <div>
        <Loader :isLoading="isLoading"></Loader>
        <div id="theme-banner-one">
            <div class="illustration wow zoomIn animated" data-wow-delay="1s" data-wow-duration="2s">
                <img :src="$websiteEnvironment.assetsDir + '/website-svgs/coming-soon/' + 'coming-soon.svg'"
                     style="margin-top: 100px; width: 500px"
                     alt="" class="screen-one wow fadeInLeft animated" data-wow-duration="2s">
            </div>

            <div class="container">
                <div class="main-wrapper">
                    <h1 class="main-title wow fadeInUp animated" data-wow-delay="0.4s">
                       ....COMING SOON 
                    </h1>
                    <h4 class=" wow fadeInUp animated mt-5" data-wow-delay="0.9s">
                        قريباً ستستطيع إرسال  دعوة للمعارف والأصدقاء
                        <br>
                        <br> للتبرع لجمعية التميز
                        <br>
        
                        <br> وتكون شريكاً في الأجر والثواب
                    </h4>
                </div>
            </div>
        </div>

        
    </div>
</template>

<script>
  
    export default {
        name: 'websiteDonationInviteFormComingSoon',
        metaInfo: {
            title: 'دعوة للتبرع قريباً'
        }
    }
</script>

<style scoped>

    .solid-inner-banner {
        background: #fafcff;
        text-align: right;
        padding: 265px 0 0;
    }

    #theme-banner-one {
        background: none;
        position: relative;
    }

    #theme-banner-one .illustration {
        position: absolute;
        left: 3%;
        top: 10%;
        width: 45%;
    }

</style>
